import OrderSummary from './OrderSummary'
import React from 'react'
import { StateCustomerOrder } from 'state/reducers/customerReducer'
import styled from 'styled-components'

interface Props {
  order: StateCustomerOrder
}

export default ({ order }: Props) => {
  return !isEmpty(order) ? (
    <Container>
      <OrderContentContainer>
        <li>
          <Bold>Tilausnumero</Bold>: {order.number}
        </li>
        <li>
          <Bold>Tilauksen tila</Bold>: {order.status}
        </li>
        <li>
          <Bold>Tilauksen päivämäärä</Bold>: {order.order_date}
        </li>
        <Subtitle>Tilatut tuotteet</Subtitle>
        {order.items.map((item, index) => (
          <ProductRow key={index}>
            <Quantity>{item.quantity_ordered} x</Quantity>{' '}
            <span>{item.product_name}</span>
            <span>{Format(item.product_sale_price.value)} €</span>
          </ProductRow>
        ))}
      </OrderContentContainer>
      <OrderSummary total={order.total.grand_total.value} />
    </Container>
  ) : (
    <OrderContentContainer>Tilauksessa ei tuotteita</OrderContentContainer>
  )
}

const Format = (number: number) => {
  return number.toFixed(2).replace('.', ',')
}

const isEmpty = (order: StateCustomerOrder) => {
  return (
    !order ||
    !order.items ||
    (order.items.length === 1 && order.items[0].quantity_ordered === 0)
  )
}

const Container = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  justify-content: center;
  padding: 24px 0;
`

const OrderContentContainer = styled.ul`
  font-size: clamp(0.8rem, 1vw, 1.1rem);
  padding: 15px;
  background: #fbfbfb;
  box-shadow: 2px 2px 12px rgba(96, 96, 96, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ProductRow = styled.li`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 500;
  span:last-child {
    flex-grow: 2;
    text-align: right;
  }
`

const Subtitle = styled.div`
  font-weight: bold;
`

const Bold = styled.span`
  font-weight: bold;
`

const Quantity = styled.span`
  color: ${props => props.theme.primaryGreen};
`
