import React, { useEffect, useState } from 'react'
import { purchaseGA } from 'gtag.js/utils'
import ContentContainer from 'components/ContentContainer'
import CustomerOrder from 'components/CustomerOrder'
import { H1 } from '@mehilainen/design-system'
import { giftCardSku } from './ProductGiftCard'
import styled from 'styled-components'
import { type MagentoCustomerOrder } from 'requests/graphql/fragments/customerOrdersFragment'
import { Cookies } from 'react-cookie'

type MagentoSuccessCartContentsCookie = {
  currency: 'EUR' // hard coded
  value: string // "grand_total": '100.0000'
  tax: 0 // hard coded
  shipping: 0 // hard coded
  transaction_id: string // '000000114'
  items: {
    item_id: string // 'lahjakortti'
    item_name: string // 'Mehil\u00e4isen lahjakortti'
    price: string // '100.0000'
    quantity: string // '1.0000'
  }[]
}

export default () => {
  const [order, setOrder] = useState<MagentoCustomerOrder>()

  useEffect(() => {
    /*     
    // mock data
    const orderCookie = {
      currency: 'EUR',
      value: '498.0000',
      tax: 0,
      shipping: 0,
      transaction_id: '000000114',
      items: [
        {
          item_id: 'huoleton',
          item_name: 'Huoleton-terveystarkastus',
          price: '109.0000',
          quantity: '1.0000',
        },
        {
          item_id: 'liikkujan',
          item_name: 'Liikkujan terveystarkastus',
          price: '189.0000',
          quantity: '1.0000',
        },
        {
          item_id: 'lahjakortti',
          item_name: 'Mehil\u00e4isen lahjakortti',
          price: '200.0000',
          quantity: '1.0000',
        },
      ],
    } 
    */

    const cookies = new Cookies()
    const orderCookie = cookies.get<MagentoSuccessCartContentsCookie>(
      'mehi_success_cart_contents'
    )

    if (orderCookie) {
      // transform cookie data to type MagentoCustomerOrder
      const newOrder: MagentoCustomerOrder = {
        id: orderCookie.transaction_id,
        number: parseInt(orderCookie.transaction_id),
        order_date: new Date().toLocaleString(),
        status: 'completed',
        billing_address: {},
        total: {
          grand_total: {
            value: parseFloat(orderCookie.value),
            currency: orderCookie.currency,
          },
        },
        items: orderCookie.items.map(item => ({
          product_name: item.item_name,
          product_sku: item.item_id,
          product_sale_price: {
            value: parseFloat(item.price),
            currency: orderCookie.currency,
          },
          quantity_ordered: parseFloat(item.quantity),
          product_url_key: item.item_id, // ?
        })),
      }

      setOrder(newOrder)
      purchaseGA(newOrder, giftCardSku)
    }
  }, [])

  return (
    <ContentContainer>
      <Header>
        <H1>Tilausvahvistus</H1>
        <p>
          {order
            ? `Olemme vastaanottaneet tilauksesi. Saat tilausvahvistuksen myös
          tilauksessa käyttämääsi sähköpostiosoitteeseen.`
            : `Tilauksessa oli ongelma, ota yhteyttä asiakaspalveluumme.`}
        </p>
      </Header>
      {order && <CustomerOrder order={order} />}
    </ContentContainer>
  )
}

const Header = styled.div`
  text-align: center;
  padding-bottom: 50px;
  max-width: 650px;
  margin: auto;
`
