import React from 'react'
import styled from 'styled-components'

interface Props {
  total: number
}

export default (props: Props) => {
  const Format = (number: number) => {
    return number?.toFixed(2).replace('.', ',')
  }
  const total = Format(props.total ?? 0)
  // TODO: Add VAT for products that are not gift cards.
  // const vat24 = Format(cartContent.prices.grand_total.value * 0.24)
  // const vatless = Format(cartContent.prices.grand_total.value * 0.76)

  return (
    <OrderSummaryContainer>
      <SummaryRow>
        Tuotteiden välisumma <span>{total} €</span>
      </SummaryRow>
      <SummaryRow>
        {/* Hard coded because all products are gift cards */}
        Vero (0 % alv) 0 €
      </SummaryRow>
      <SummaryRow>
        Kokonaissumma <Total>{total} €</Total>
      </SummaryRow>
    </OrderSummaryContainer>
  )
}

const OrderSummaryContainer = styled.div`
  font-size: clamp(0.8rem, 1vw, 1.1rem);
  padding: 15px;
  background: #fbfbfb;
  box-shadow: 2px 2px 12px rgba(96, 96, 96, 0.1);
  border-radius: 4px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;

  &:last-of-type {
    border-top: 1px solid #dddddd;
    padding-top: 15px;
  }
`

const Total = styled.span`
  font-size: clamp(1rem, 1vw, 1.3rem);
`
